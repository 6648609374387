<template>
    <div id="OrgUpdateAndDelete">
        <v-container>
            <v-form @submit.prevent="InsertAndUpdate()">
                <v-row>
                    <v-col cols="12" sm="12" md="12" lg="6">
                        <v-text-field label="รหัสสาขา"
                                      placeholder="รหัสสาขา"
                                      outlined
                                      required
                                      dense
                                      v-model="Organization.OrganizationCode"></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="12" md="12" lg="6">
                        <v-text-field label="ชื่อ"
                                      placeholder="ชื่อ"
                                      outlined
                                      required
                                      dense
                                      v-model="Organization.OrganizationName"></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="12" md="6" lg="3">
                        <v-text-field label="เลขผู้เสียภาษี"
                                      placeholder="เลขผู้เสียภาษี"
                                      outlined
                                      required
                                      dense
                                      v-model="Organization.OrganizationTex"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="3">
                        <v-text-field label="เบอร์โทรศัพ"
                                      placeholder="เบอร์โทรศัพ"
                                      outlined
                                      required
                                      dense
                                      v-model="Organization.OrganizationTel"></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="12" md="12" lg="6">
                        <v-textarea label="หมายเหตุ"
                                    placeholder="หมายเหตุ"
                                    outlined
                                    dense
                                    v-model="Organization.OrganizationRemark"></v-textarea>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="1" sm="1" md="1" lg="1">
                        <v-btn block type="submit">บันทึก</v-btn>
                    </v-col>
                    <v-col cols="1" sm="1" md="1" lg="1">
                        <v-btn block @click="$router.push('/Org')">กลับ</v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-container>
    </div>
</template>
<script>
    import axios from "axios";
    export default {
        data: () => ({
            Organization: {
                OrganizationId: null,
                OrganizationCode: "",
                OrganizationName: "",
                OrganizationRemark: "",
                OrganizationTel: "",
                OrganizationTex: "",
                OrganizationZipCode: ""
            }
        }),
        mounted() {
            this.$nextTick(() => {
                if (this.$store.state.User.UserLevel == "M") {
                    if (this.$route.query.OrganizationId != null) {
                        this.Organization.OrganizationId = this.$route.query.OrganizationId;
                        this.SelectId(this.Organization.OrganizationId);
                    }
                } else {
                    this.$router.push("/Member");
                }

            });
        },
        methods: {
            InsertAndUpdate() {
                if (this.Organization.OrganizationId != null) {
                    //Update
                    axios
                        .put("/Api/V1/Update/Update/Organization", this.Organization)
                        .then(res => {
                            if (res.status == 200) {
                                this.$router.push("/Org");
                            }
                        });
                } else {
                    // Insert
                    axios
                        .post("/Api/V1/Insert/Insert/Organization", this.Organization)
                        .then(res => {
                            if (res.status == 200) {
                                this.$router.push("/Org");
                            }
                        });
                }
            },
            SelectId(v) {
                axios
                    .get("/Api/V1/Select/SelectId/Organization?OrganizationId=" + v)
                    .then(res => {
                        if (res.status == 200) {
                            this.Organization = res.data;
                        }
                    });
            }
        }
    };
</script>